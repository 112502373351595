<template>
    <!-- <script v-html="jsonld" type="application/ld+json"> -->
    <div id="job-listing">
        <div class="container">
            <router-link :to="{name: 'Careers'}">
                <BackButton backButtonText="Back to Careers" />
            </router-link>
            <div class="columns is-centered">
                <div class="column is-8">
                    <section class="section" role="main">
                        <header>
                            <h1 class="title has-underline">
                                <slot name="jobTitle" />
                            </h1>
                        </header>

                        <div class="container job-description">
                            <slot name="jobDescription" />
                        </div>

                        <div class="mt-5">
                            <h4 class="title is-5">Interested?</h4>
                            <slot name="howToApply" />
                        </div>
                    </section>

                    <section class="section">
                        <hr />
                        <p class="is-italic">
                            We do NOT accept applications submitted through job sites
                            such as Indeed, Dice, and Monster.
                            Direct applicants ONLY.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackButton from '@/components/backbutton.vue'

export default {
    components: {
        BackButton
    }
    // data () {
    //     const jsonld = {
    //         "@context" : "https://schema.org/",
    //         "@type" : "JobPosting",
    //         "title" : jobTitle,
    //         "description" : jobDescription,
    //         "identifier": {
    //             "@type": "PropertyValue",
    //             "name": jobTitle,
    //             "value": "1234567"
    //         }
    //     }
    // }
}
</script>