<template>
    <transition name="popupMessage">
        <a v-if="showPopup"
           id="popupMessage"
           class="content"
           :href="popup.pageUrl"
           :target="target">
            <vue-markdown-plus>{{ popup.message }}</vue-markdown-plus>
        </a>
    </transition>
</template>

<script>
import VueMarkdownPlus from 'vue-markdown-plus'

export default {
    name: 'Popup',
    components: {
        'vue-markdown-plus': VueMarkdownPlus
    },
    data: () => {
        return {}
    },
    mounted() {
        if (!this.$store.state.popup) this.$store.dispatch('getPopup')
    },
    computed: {
        popup() {
            return this.$store.state.popup
        },
        target() {
            // if () return '_top' // relative path
            if (!this.popup.pageUrl.host || 
            this.popup?.pageUrl?.host === window.location.host) {
                return '_top'
            }
            return '_blank'
        },
        showPopup() {
            if (this.popup && this.popup.message && this.popup.showPopup) {
                return true
            }
            return false
        }
    },
    methods: {}
}
</script>
