<template>
    <!-- Single News Article Listing page layout -->
    <news-template v-if="news" :key="newsKey">
        <template #newsTitle>{{ news.title }}</template>

        <template #newsDate>{{ 'Published: ' + formatDate(news.published_at) }}</template>

        <template #newsImage>
            <div
                class="news-image"
                :alt="news.image.alternativeText"
                :style="{ backgroundImage: `url(${image})` }"
            />
        </template>

        <!-- Main News Content -->
        <template #newsBody>
            <vue-markdown-plus
                :anchor-attributes="{target: '_blank'}"
                :scr="strapiApiUrl"
                class="content"
                :class="{'is-draft':!news.published_at}"
            >{{ news.body }}</vue-markdown-plus>
        </template>

        <template #newsTags>
            <span
                :class="'tag is-primary has-text-weight-bold is-radiusless mr-3'"
                v-for="tag in news.tags"
                :key="tag.tag.id"
                target="_blank"
            >{{ tag.tag}}</span>
        </template>
    </news-template>
</template>

<script>
import newsTemplate from '../components/newsArticleTemplate.vue'
import VueMarkdownPlus from 'vue-markdown-plus'

export default {
    name: 'NewsArticle',
    components: {
        'vue-markdown-plus': VueMarkdownPlus,
        newsTemplate
    },
    data() {
        return {
            newsKey: 0,
            pageTitle: '',
            routeParam: this.$route.params.id
        }
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.news.title,
            titleTemplate: 'Eduworks | %s'
        }
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl
        },
        news() {
            var id = this.$route.params.id
            var articles = this.$store.state.eduworksNews
            // get news by id
            var newsArticle = articles.filter(function (elem) {
                if (elem.url === id) return elem
            })[0]
            return newsArticle
        },
        image() {
            if (this.news.image) {
                if (this.news.image.formats && this.news.image.formats.medium) {
                    return (
                        this.strapiApiUrl + this.news.image.formats.medium.url
                    )
                } else if (
                    this.news.image.formats.large &&
                    this.news.image.formats.large.url
                ) {
                    return this.strapiApiUrl + this.news.image.formats.large.url
                } else {
                    return this.strapiApiUrl + this.news.image.url
                }
            }
            return null
        }
    },

    watch: {
        news: function () {
            this.pageTitle = this.news.title
        }
    },
    mounted() {
        if (this.$store.state.eduworksNews.length < 1)
            this.$store.dispatch('getEduworksNews')
    },
    methods: {
        notEmpty(data) {
            if (!data) return false
            return true
        },
        formatDate(date) {
            if (!date) return 'Unpublished'
            let splitDate = date.split('T')
            let formattedDate = splitDate[0]
            return formattedDate
        }
    }
}
</script>
