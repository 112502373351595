<template>
    <div role="main">
        <section class="hero has-background-clouds">
            <div class="hero-body">
                <div class="container">
                    <!-- <div class="columns is-centered">
                        <div class="column is-two-thirds">
                            <img style="width: 50%;"
                                 src="@/views/Tides/assets/TIDES-logo-color.svg" />
                            <h1 class="title is-3">
                                Training Needs & Training Effectiveness Analysis
                            </h1>
                        </div>
                    </div> -->
                    <div class="columns is-centered">
                        <div class="column is-one-third">
                            <img src="@/views/Tides/assets/TIDES-logo-color.svg"
                                 alt="TIDES logo" />
                            <img src="@/views/Tides/assets/tides-banner.png"
                                 alt="Digram showing the TIDES process. 1. Uses Al to seamlessly ingest reference data from multiple sources. 2. TIDES connects competencies and observable behaviorsto TNA. 3. Competency assessments and training effectiveness analytics can be viewed through custom dashboards." />
                        </div>
                        <div class="column is-one-third">
                            <h1 class="title is-2 mt-6">Training Needs & Training Effectiveness Analysis</h1>
                            <p class="subtitle mt-4">TIDES effectively addresses the training curriculum development
                                challenges within the industry. TIDES employs a web-based platform that adheres to the
                                Aviation Standard, S6000T process. The platform serves as a comprehensive guide, assisting
                                both experienced and novice Instructional Designers throughout the entire process of
                                learning content analysis, design, development, and management. </p>
                            <p>TIDES enables and enhances <strong>CBTA</strong> as it connects tasks with
                                <strong><em>Observable Behaviors</em></strong> and <strong><em>Competencies</em></strong>,
                                that together with Knowledge, Skills and Attitudes, support the development of Learning
                                Objectives and curriculum that is in line with the Industries push towards
                                <strong><em>Competency Based Training Assessments</em></strong>.
                            </p>
                            <div class="mt-6 has-text-centered">
                                <img style="width: 70%;"
                                     src="@/views/Tides/assets/sketch-airplane.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-light">
            <div class="hero-body">
                <div class="container">
                    <h2 class="title is-1 mb-6 has-text-centered">
                        Features
                    </h2>
                    <div class="columns is-multiline is-centered">
                        <div class="column is-half-tablet is-one-third-desktop">
                            <div class="box is-outlined">
                                <p class="box-title">Productivity / Cost Savings</p>
                                <p>From customer testimonials, TIDES reduces the time to complete a Training Needs Analysis
                                    by 50%, essentially Doubling Output with the same amount of resources.</p>
                            </div>
                        </div>
                        <div class="column is-half-tablet is-one-third-desktop">
                            <div class="box is-outlined">
                                <p class="box-title">Chain of evidence and regulatory traceability</p>
                                <p>Tracks regulatory compliance throughout the training needs analysis process. Produces
                                    persistent and managed end-to end chains of evidence for reporting and tracking.</p>
                            </div>
                        </div>
                        <div class="column is-half-tablet is-one-third-desktop">
                            <div class="box is-outlined">
                                <p class="box-title">Artificial intelligence</p>
                                <p>TIDES’s AI ingests data sources and produces reusable competency frameworks that conform
                                    to standards and best practices and that are traceable back to the sources used to
                                    generate them.</p>
                            </div>
                        </div>
                        <div class="column is-half-tablet">
                            <div class="box is-outlined">
                                <p class="box-title">Training Effectiveness (Competency Analysis)</p>
                                <p>TIDES enables the evaluation of training effectiveness by taking in Instrumented data
                                    from Simulators, Computer Based Training (CBT), Instructor Assessments, Learning Record
                                    Stores, Learning Management Systems, and others and allows Eduworks to work with the
                                    customer to build, customized algorithms and dashboards to identify training
                                    effectiveness and competencies, gaps in training, trainee retention and more.</p>
                            </div>
                        </div>
                        <div class="column is-half-tablet is-one-third-desktop">
                            <div class="box is-outlined">
                                <p class="box-title">Develop the optimal training solution</p>
                                <p>Defines training system requirements and helps estimate costs based on instructional
                                    media requirements that can be traced back to original documented requirements. Provides
                                    reports to help leadership select the optimal training to meet regulatory and budgetary
                                    needs.</p>
                            </div>
                        </div>
                    </div>
                    <div class="has-text-centered">
                        <a class="mx-1 button is-primary"
                           href="./documents/TIDES_commercial_training-needs-and-effectivness.pdf"
                           target="_blank">
                            <span class="icon"><i class="fa-solid fa-file-arrow-down"></i></span>
                            <span>Download Commercial 1-pager</span>
                        </a>
                        <a class="mx-1 button is-primary"
                           href="./documents/TIDES_military_training-needs-and-effectivness.pdf"
                           target="_blank">
                            <span class="icon"><i class="fa-solid fa-file-arrow-down"></i></span>
                            <span>Download Military 1-pager</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-three-fifths-desktop">
                            <p class="subtitle is-3 my-6 has-text-centered">
                                <i class="fa-solid fa-angles-right"></i>
                                For further information on TIDES and to schedule
                                a demonstration please <strong><a href="/contact">contact us</a></strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: "TIDES",
    data: () => {
        return {
            name: "TIDES",
        };
    },
    metaInfo: {
        title: "TIDES",
        template: null,
    },
};
</script>

<style lang="scss" scoped>
@import 'tides-styles.scss';
</style>
