export default {
    getStrapiData (collectionApi, config, successCallback, failureCallback) {
        // 'collectionApi' should be the strapi URL + collection ID, ex: '/job-listings' or '/blogs'
        // 'config' is an optional string to tell strapi to sort, filter, or limit the results. For config options see: 
        // https://strapi.io/documentation/developer-docs/latest/developer-resources/content-api/content-api.html#filters

        // Include unpublished "draft" content only on dev servers
        // if (window.location.hostname === 'localhost' || window.location.hostname === 'dev.eduworks.com') {
        //     if (config) config += '&';
        //     config += '_publicationState=preview';
        //     console.log('-------------- GETTING STRAPI ' + collectionApi + ' -----------');
        // }

        const xhr = new XMLHttpRequest();
        xhr.open('GET', collectionApi + '?' + config);
        xhr.setRequestHeader('Content-Type', 'application/json');
        const xhrx = xhr;
        xhr.onreadystatechange = function () {
            if (xhrx.readyState === 4 && xhrx.status === 200) {
                if (successCallback != null) {
                    successCallback(xhrx.responseText);
                    if (window.location.hostname === 'localhost' || window.location.hostname === 'dev.eduworks.com') {
                        console.log('success: ', JSON.parse(xhrx.responseText));
                    }
                    return successCallback(xhrx.responseText);
                }
            } else if (xhrx.readyState === 4) {
                if (failureCallback != null) {
                    failureCallback(xhrx.responseText);
                    console.log('error: ', xhrx.responseText);
                    return failureCallback(xhrx.responseText);
                }
            }
        };
        xhr.send();
    }
};
