<template>
    <!-- Single Job Listing page layout -->
    <!-- TODO: Update html to match actual job listing layout -->
    <job-template v-if="job" :key="jobKey">
        <template #jobTitle>{{ job.title }}</template>

        <!-- <template #jobDate>
            {{ $date(job.published).format("YYYY-MM-DD") }}
        </template>-->

        <!-- Main Job Content -->
        <template #jobDescription>
            <vue-markdown-plus
                class="content"
                :anchor-attributes="{
                    target: '_blank',
                }"
                :scr="strapiApiUrl"
                :class="{'is-draft':!job.published_at}"
            >{{ job.description }}</vue-markdown-plus>
        </template>

        <template #howToApply>
            <vue-markdown-plus
                :anchor-attributes="{
                target: '_blank',
                }"
            >{{ job.howToApply }}</vue-markdown-plus>
        </template>
    </job-template>
</template>

<script>
import JobTemplate from '../components/jobTemplate.vue'
import VueMarkdownPlus from 'vue-markdown-plus'

export default {
    name: 'JobListing',
    components: {
        'vue-markdown-plus': VueMarkdownPlus,
        JobTemplate
    },
    data() {
        return {
            jobKey: 0,
            pageTitle: '',
            routeParam: this.$route.params.id
        }
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.job.title + ' Opening',
            titleTemplate: 'Eduworks | %s'
        }
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl
        },
        job: {
            get: function () {
                var jobs = this.$store.state.jobs
                var jobId = this.$route.params.id
                // get job by id
                var job = jobs.filter(function (elem) {
                    if (elem.url === jobId) return elem
                })[0]
                if (job) {
                    // console.log(job.description);
                    // Fix relative URLs for strapi media
                    job.description = job.description
                        .split('(/uploads/')
                        .join(`(${this.strapiApiUrl}/uploads/`)
                }
                return job
            }
        }
    },

    // TODO: For some reason the page only updates on refresh,
    // the Job data won't fire when the page is loaded via Router

    watch: {
        job: function () {
            // whenever job data changes this function will run
            this.pageTitle = this.job.title
        }
    },
    mounted() {
        if (this.$store.state.jobs.length < 1) this.$store.dispatch('getJobs')
    },
    methods: {
        notEmpty(data) {
            if (!data) return false
            return true
        }
    }
}
</script>

