<template>
    <div class="container">
        <router-link
            :to="{ path: '/case-studies'}"
        >
            <BackButton backButtonText="Back to Case Studies" class="pb-6" />
        </router-link>
        <section class="section is-large pt-0 pb-6">
            <div class="columns case-study-header-columns vh-small">
                <div class="column is-5 mr-6 has-vertically-aligned-content">
                    <h1 class="title l">
                        <slot name="caseStudyTitle"/>
                    </h1>
                    <p>
                        <slot name="caseStudyDescription"/>
                    </p>
<!-- TO DO: FIGURE OUT TAGS -->
                    <div class="block mt-5">
                        <!-- <div class="tag is-primary has-text-weight-bold mb-4 is-radiusless mr-3 mt-6"> -->
                            <slot name="caseStudyTags"/>
                        <!-- </div> -->
                        
                    </div>
                </div>
                <div class="column is-half">
                    <slot name="caseStudyImage"/>
                </div>
            </div>
        </section>
        <div class="columns">
            <div class="column is-1"></div>
            <div class="column is-10">
        <section class="section is-small mb-6">
            <div class="title l pb-5">
                <img src="../assets/the-ask.png" class="case-study-icon">
                The Ask
            </div>
            <div class="subtitle ml-6">
                <slot name="caseStudyAsk"/>
            </div>
        </section>
        <section class="section is-small mb-6">
            <div class="title l pb-5">
                <img src="../assets/our-process.png" class="case-study-icon">
                Our Process
            </div>
            <div class="subtitle ml-6">
               <slot name="caseStudyProcess"/>
            </div>
        </section>
        <section class="section is-small mb-6">
            <div class="title l pb-5">
                <img src="../assets/results.png" class="case-study-icon">
                Results
            </div>
            <div class="subtitle ml-6">
                <slot name="caseStudyResults"/>
            </div>
        </section>
        <section class="section is-small has-text-centered">
            <div class="title is-4">Like what you see?</div>
            <router-link :to="{name: 'Contact'}"><button class="button is-primary is-radiusless has-text-weight-bold primary-button">Contact Us<i class="fas fa-arrow-right ml-2 primary-button-icon"></i></button></router-link>
        </section>
            </div>
            <div class="column is-1"></div>
            </div>

    </div>  
</template>

<script>
import BackButton from '@/components/backbutton.vue'

export default {
    
    components: {
        BackButton
    },
    
};
</script>

