<template>

    <div id="news-article">
        <header>
            <div class="mx-4">
                <div class="container">
                    <router-link :to="{name: 'Events'}"><BackButton backButtonText="Back to Events" /></router-link>
                    <div class="columns is-centered">
                        <div class="column is-8">
                            <div class="pb-6">
                                <slot name="newsImage"/>
                            </div>
                            <h1 class="title has-underline">
                                <slot name="newsTitle" />
                            </h1>
                            <slot name="newsDate"/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <section role="main">
            <div class="m-4">
                <div class="container news-body">
                    <div class="columns is-centered">
                        <div class="column is-8">
                            <slot name="newsBody" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BackButton from '@/components/backbutton.vue'

export default {
    
    components: {
        BackButton
    }
};
</script>

