<template>
    <div class="block social-icons">
        <!-- <a href="https://www.facebook.com/EduworksCorporation" target="_blank" class="social-icon"><i class="fab fa-facebook-square fa-2x"></i></a> -->
        <a
            title="Eduworks on X/Twitter"
            href="https://twitter.com/eduworks?lang=en"
            target="_blank"
            class="social-icon"
        >
        <i class="fa-brands fa-square-x-twitter fa-2x"></i>
        </a>
        <a
            title="Eduworks on LinkedIn"
            href="https://www.linkedin.com/company/eduworks/"
            target="_blank"
            class="social-icon"
        >
            <i class="fab fa-linkedin fa-2x"></i>
        </a>
    </div>
</template>

<script>
export default {
    name: 'SocialMediaIcons'
}
</script>