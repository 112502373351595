<template>
            <div>
                 <button class="button has-text-weight-bold transparent-button mb-4">
                    <span class="icon">
                    <i class="fas fa-arrow-left"></i>
                    </span>
                    {{ backButtonText }}
                </button>
            </div>
</template>

<script>
export default {
  name: 'BackButton',
  components: {
  },
  props: ['backButtonText']
}
</script>