<template>
    <div role="main">
        <section class="section columns is-centered vh " style="display:flex;justify-content:center;align-items:center;">
            <div class="container has-text-centered column is-10">
                <h1 class="title xl mt-5">Machine Learning to <br>Accelerate Training Development</h1>
                <h4 class="title is-4 mb-6" style="width: 75%; margin: 0 auto;">Using automated content management to streamline &amp; improve efficiency of developing employee training programs.</h4>
                <router-link
                    to="/contact"
                    class="
                        button
                        is-large is-primary is-radiusless
                        has-text-weight-bold
                        is-block
                        mt-4
                        is-inline-block
                        primary-button
                    "
                >
                    Request a Demo
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </router-link>
            </div>
        </section>
        <section class="section">
            <div class="container content">
                <div class="columns is-centered">
                    <div class="column is-9">
                        <div class="columns">
                            <div class="column">
                                <img src="./assets/flowchart-stock.jpg"/>
                            </div>
                            <div class="column">
                                <p>
                               Eduworks develops sophisticated content management tools to propel training development to new levels of efficiency.</p>
                               
                               <p class="subtitle is-5 ml-0 my-4">Our goal is to streamline training development and incorporate new and updated content with ease. </p>
                               <p>
                               We apply powerful machine learning techniques, developed by Eduworks for U.S. Government clients, to make training content more manageable, scalable, and maintainable.
                               </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section is-medium has-background-light">
            <div class="container content">
                <div class="columns is-centered" style="margin: 0 auto">
                    <div class="column is-6 
                    has-text-centered">
                    <img  style="width: 100px; border-radius: 10000px; padding: .5rem; background-color: rgba(189,42,47,.1)" src="./assets/ai-solutions.png" class="mb-5">
                        <div class="title mb-5">Custom AI Solutions</div>
Eduworks is proud to offer a suite of AI services backed by investments from multiple DoD sponsors and the National Science Foundation. Our services have been trained on key domains like leadership, tactics, and manufacturing using data from the National Labor Exchange and other sources, and are ready to use right out of the box. Plus, we offer domain customization and tailoring to create custom models that can be deployed as a SaaS solution or on-premise.
 <div>                   
<a class="button is-primary mt-6"
                                       href="./documents/Talent-Cascade_Custom-AI-Services.pdf"
                                       target="_blank">
                                        <span class="icon"><i class="fa-solid fa-file-arrow-down"></i></span>
                                        <span>Download one-pager</span>
                                    </a></div>
</div>
                </div>
            </div>
        </section>
        <section class="section is-medium">
            <div class="container content">

            <div class="columns is-centered" style="width: 72%; margin: 0 auto;">
                <div class="column">
                    <div class="title l">How it works</div>
                </div>
            </div>

            <div class="columns is-centered" style="width: 60%; margin: 0 auto;">
                <div class="column is-6 hero is-dark">
                    <div class="hero-body">
                        <h3 class="title is-3 ">What you give</h3>
                            <ul>
                                <li>Training Objectives</li>
                                <li>Training documents to be analyzed</li>
                            </ul>
                    </div>
                </div>
                <div class="column is-2 has-text-centered" style="display:flex;justify-content:center;align-items:center;">
                    <i class="fas fa-arrow-right primary-button-icon has-text-primary fa-3x"></i>
                </div>
                <div class="column is-6 hero is-dark">
                    <div class="hero-body">
                        <h3 class="title is-3 ">What you get</h3>
                            <ul>
                                <li>Topic Maps</li>
                                <li>Topic labels</li>
                                <li>Relations between topics</li>
                                <li>Knowledge graphs</li>
                                <li>Content excerpts</li>
                                 <li>AI Generated assessment questions</li>
                                  <li>Custom outputs through new modules built for your needs</li>
                            </ul>
                    </div>
                </div>
            </div>
            </div>
        </section>
        <section class="hero is-medium is-light">
            <div class="hero-body container">
                <div class="has-text-centered">
                    <h3 class="title is-3 mb-5">
                        Possible Output Formats
                    </h3>
                </div>
                <div class="columns is-centered is-vcentered">
                    <div class="column">
                        <img style="width: 200px" src="./assets/ppt-logo.png">

                    </div>
                    <div class="column">
                       <img style="width: 150px" src="./assets/xml-logo.png">
                    </div>
                    <div class="column">
                        <img  style="width: 150px" src="./assets/articulate-logo.png">
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-medium">
            <div class="hero-body has-text-centered">
                <h3 class="title is-3">Ready to learn more?</h3>
                <router-link
                    to="/contact"
                    class="
                        button
                        is-large is-primary is-radiusless
                        has-text-weight-bold
                        is-block
                        mt-4
                        is-inline-block
                        primary-button
                    "
                >
                    Get Started
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Content Management",
    data: () => {
        return {
            name: "Content Management",
        };
    },
    metaInfo: {
        title: "Content Management",
        template: null,
    },
};
</script>

<style scoped>
.card {
    height: 100%;
}
</style>
