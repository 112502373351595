import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles.scss'
import "@/fonts/fontawesome/css/all.min.css"
import * as VueGoogleMaps from 'vue2-google-maps'
import ScrollAnimation from './directives/scrollanimation'
import VueGtag from 'vue-gtag'

Vue.directive('scrollanimation', ScrollAnimation);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCgct31L7sQfNZfxlt_0DCsrnfJOmMdKV8',
    installComponents: true,
    libraries: 'places',
  },
})

Vue.use(VueGtag, {
  config: {
      id: 'G-QMR230NJ9L'
  }
}, router)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


