<template>
    <div id="app">
        <!-- TODO: Create and add a navbar component here -->
        <Navbar @fix-background="mobileVisible" />
        <div id="main-content"
             :class="{ 'fixed': mobileMenuVisible }"
             class="page-content">
            <transition name="fade">
                <router-view />
            </transition>
        </div>
        <Footer :mobileMenuVisible="mobileMenuVisible" />
    </div>
</template>

<script>
import Navbar from '@/components/navbar.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'App',
    components: {
        Navbar,
        Footer
    },
    metaInfo: {
        // Default subtitle if no subcomponent (%s) is defined by other page views
        title: 'Bringing the data revolution to training & talent management',
        // all titles will be injected into this template unless template is set to null
        titleTemplate: 'Eduworks | %s'
    },
    data: () => {
        return {
            counter: 1,
            mobileMenuVisible: ''
        }
    },
    methods: {
        mobileVisible(value) {
            this.mobileMenuVisible = value;
            this.counter = this.counter + 1
            // console.log(this.counter)
            // console.log(this.mobileMenuVisible)
        }
    }
}
</script>



