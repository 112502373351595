<template>
    <!-- Single Case Study page layout -->
    <caseStudy-template v-if="caseStudy" :key="caseStudyKey">
        <template #caseStudyTitle>{{ caseStudy.title }}</template>

        <template #caseStudyImage>
            <div
                v-if="notEmpty(caseStudy.image)"
                :style="{ backgroundImage: `url(${strapiApiUrl + caseStudy.image.url})` }"
                :alt="caseStudy.image.alternativeText"
                class="case-study-img"
                :class="{'is-draft':!caseStudy.published_at}"
            />
        </template>

        <!-- Main Content -->
        <template #caseStudyDescription>
            <vue-markdown-plus
                :anchor-attributes="{target: '_blank'}"
                :scr="strapiApiUrl"
                :class="{'is-draft':!caseStudy.published_at}"
            >{{ caseStudy.description }}</vue-markdown-plus>
        </template>

        <template #caseStudyAsk>
            <vue-markdown-plus
                :anchor-attributes="{target: '_blank'}"
                :scr="strapiApiUrl"
            >{{ caseStudy.ask }}</vue-markdown-plus>
        </template>

        <template #caseStudyProcess>
            <vue-markdown-plus
                :anchor-attributes="{target: '_blank'}"
                :scr="strapiApiUrl"
            >{{ caseStudy.process }}</vue-markdown-plus>
        </template>

        <template #caseStudyResults>
            <vue-markdown-plus
                :anchor-attributes="{target: '_blank'}"
                :scr="strapiApiUrl"
            >{{ caseStudy.results }}</vue-markdown-plus>
        </template>

        <template #caseStudyTags>
            <span
                v-for="tag in caseStudy.tags"
                :key="tag.tag.id"
                class="tag is-primary has-text-weight-bold is-radiusless mr-3"
            >{{ tag.tag}}</span>
        </template>
    </caseStudy-template>
</template>

<script>
import caseStudyTemplate from '../components/caseStudyTemplate.vue'
import VueMarkdownPlus from 'vue-markdown-plus'

export default {
    name: 'CaseStudies',
    components: {
        'vue-markdown-plus': VueMarkdownPlus,
        caseStudyTemplate
    },
    data() {
        return {
            caseStudyKey: 0,
            pageTitle: 'Case Study',
            routeParam: this.$route.params.id
        }
    },
    metaInfo() {
        return {
            // title will be appended into the titleTemplate in App.vue
            title: this.caseStudy.title + ' Case Study',
            titleTemplate: 'Eduworks | %s'
        }
    },
    computed: {
        strapiApiUrl() {
            return this.$store.state.strapiApiUrl
        },
        caseStudy: {
            get: function () {
                var caseStudies = this.$store.state.caseStudies
                var caseStudyId = this.$route.params.id
                // get case study by id
                var caseStudy = caseStudies.filter(function (elem) {
                    if (elem.url === caseStudyId) return elem
                })[0]
                return caseStudy
            }
        }
    },
    watch: {
        caseStudy: function () {
            this.pageTitle = this.caseStudy.title
        }
    },
    mounted() {
        if (this.$store.state.caseStudies.length < 1)
            this.$store.dispatch('getCaseStudies')
    },
    methods: {
        notEmpty(data) {
            if (!data) return false
            return true
        }
    }
}
</script>

