import Vue from 'vue';
import Vuex from 'vuex';
import strapi from '../../api/strapi.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // Global values used by components

        strapiApiUrl: 'https://cms.eduworks.com',
        // peblApiUrl: 'https://cms.pebl.pro',
        jobs: [],
        caseStudies: [],
        popup: null,
        termsAndConditions: null,
        about: null,
        products: [],
        teamMembers: [],
        allNewsArticles: [],
        loadedNewsSources: [],
        eduworksNews: [],
        // peblBlogs: []
    },
    getters: {
        // Getters are computed properties for the store state

        strapiApiUrl: state => {
            return state.strapiApiUrl;
        },
        // peblApiUrl: state => {
        //     return state.peblApiUrl;
        // },
        jobs: state => {
            return state.jobs;
        },
        caseStudies: state => {
            return state.caseStudies
        },
        popup: state => {
            return state.popup
        },
        termsAndConditions: state => {
            return state.termsAndConditions
        },
        about: state => {
            return state.about
        },
        products: state => {
            return state.products
        },
        teamMembers: state => {
            return state.teamMembers
        },
        allNewsArticles: state => {
            return state.allNewsArticles
        },
        loadedNewsSources: state => {
            return state.loadedNewsSources
        },
        eduworksNews: state => {
            return state.eduworksNews
        },
        // peblBlogs: state => {
        //     return state.peblBlogs
        // }
    },
    mutations: {
        // Mutations are functions that changes the state 
        // with a minimum amount of logic, must be synchroneous

        setJobs (state, val) {
            state.jobs = val;
        },
        setCaseStudies (state, val) {
            state.caseStudies = val;
        },
        setPopup (state, val) {
            state.popup = val;
        },
        setTermsAndConditions (state, val) {
            state.termsAndConditions = val;
        },
        setAbout (state, val) {
            state.about = val;
        },
        setProducts (state, val) {
            state.products = val;
        },
        setTeamMembers (state, val) {
            state.teamMembers = val;
        },
        setAllNewsArticles (state, val) {
            state.allNewsArticles = val;
        },
        setLoadedNewsSources (state, val) {
            state.loadedNewsSources = val;
        },
        setEduworksNews (state, val) {
            state.eduworksNews = val;
        },
        // setPeblBlogs (state, val) {
        //     state.peblBlogs = val;
        // }
    },
    actions: {
        // Actions set value in state (committing one or more mutations)
        // or set and get values using a rest api

        getJobs ({ commit, state }) {
            var collection = 'job-listings';
            var config = '_sort=updated_at:DESC';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                var jobs = JSON.parse(data);
                jobs.forEach((item) => {
                    // Clean urls from rich text: 'description'
                    if (item.body) item.description = item.description.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setJobs', jobs);
            });
        },
        getCaseStudies ({ commit, state }) {
            var collection = 'case-studies';
            var config = '_sort=created_at:DESC';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                var caseStudies = JSON.parse(data);
                caseStudies.forEach((item) => {
                    // Clean urls from rich text: 'body'
                    if (item.body) item.body = item.body.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setCaseStudies', caseStudies);
            });
        },
        getPopup ({ commit, state }) {
            var collection = 'popup';
            var config = '';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                commit('setPopup', JSON.parse(data));
            });
        },
        getTermsAndConditions ({ commit, state }) {
            var collection = 'terms-and-conditions';
            var config = '';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                var item = JSON.parse(data);
                // Clean strapi image urls in rich text: 'body'
                if (item.body) item.body = item.body.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                commit('setTermsAndConditions', item);
            });
        },
        getAbout ({ commit, state }) {
            var collection = 'about';
            var config = '';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                commit('setAbout', JSON.parse(data));
            });
        },
        getProducts ({ commit, state }) {
            var collection = 'products';
            var config = '_sort=order:ASC';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                commit('setProducts', JSON.parse(data));

            });
        },
        getTeamMembers ({ commit, state }) {
            var collection = 'team-members';
            var config = '';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                var teamMembers = JSON.parse(data);
                teamMembers.forEach((item) => {
                    // Clean urls from rich text: 'body'
                    if (item.body) item.body = item.body.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setTeamMembers', teamMembers);
            });
        },
        getAllNewsArticles ({ state, dispatch }) {
            // get news articles from all sources
            if (state.eduworksNews.length < 1) dispatch('getEduworksNews');
            // if (state.peblBlogs.length < 1) dispatch('getPeblBlogs');
        },
        addToAllNewsArticles ({ commit, state }, newNews) {
            // Check if news source already added to master list
            var sources = state.loadedNewsSources;
            if (!sources.includes(newNews.source)) {
                sources.push(newNews.source);
                commit('setLoadedNewsSources', sources);

                // Join and sort by published_at OR created_at, newest first
                var updatedNews = state.allNewsArticles.concat(newNews.news);
                updatedNews.sort(function (a, b) {
                    // if (a.published_at && b.published_at) {
                    //     return (a.published_at < b.published_at) ? -1 : ((a.published_at > b.published_at) ? 1 : 0);
                    // }
                    // else {
                    var aa = a.published_at
                    var bb = b.published_at
                    if (!a.published_at) aa = a.created_at
                    if (!b.published_at) bb = b.created_at
                    return (aa < bb) ? -1 : ((aa > bb) ? 1 : 0);
                    // }
                });
                updatedNews.reverse();
                // updatedNews.sort((a, b) => b.published_at - a.published_at).reverse();
                commit('setAllNewsArticles', updatedNews);
            }
        },
        getEduworksNews ({ commit, state, dispatch }) {
            var collection = 'news-articles';
            var config = '';
            strapi.getStrapiData(state.strapiApiUrl + '/' + collection, config, function (data) {
                var eduworksNews = JSON.parse(data);
                eduworksNews.forEach((item) => {
                    // Clean urls from rich text: 'body'
                    if (item.body) item.body = item.body.split('(/uploads/').join('(' + state.strapiApiUrl + '/uploads/');
                });
                commit('setEduworksNews', eduworksNews);

                let newsSource = 'eduworksNews';
                let loadedSources = state.loadedNewsSources;
                if (!loadedSources.includes(newsSource)) {
                    dispatch('addToAllNewsArticles', { source: newsSource, news: eduworksNews });
                }
            });
        },
        // getPeblBlogs ({ commit, state, dispatch }) {
        //     var collection = 'blogs';
        //     var config = '';
        //     strapi.getStrapiData(state.peblApiUrl + '/' + collection, config, function (data) {
        //         var peblBlogs = JSON.parse(data);
        //         peblBlogs.forEach((item) => {
        //             // Clean urls from rich text: 'body'
        //             if (item.body) item.body = item.body.split('(/uploads/').join('(' + state.peblApiUrl + '/uploads/');
        //             // Add formatting hooks
        //             item.peblBlog = true;
        //             item.tags = [{ tag: 'PeBL' }, { tag: 'Blog' }]
        //             item.externalURL = 'pebl.pro/blog/' + item.url,
        //                 item.externalNewsSource = 'Pebl Pro'
        //         });
        //         commit('setPeblBlogs', peblBlogs);
        //         let newsSource = 'peblBlogs';
        //         let loadedSources = state.loadedNewsSources;
        //         if (!loadedSources.includes(newsSource)) {
        //             dispatch('addToAllNewsArticles', { source: newsSource, news: peblBlogs });
        //         }
        //     });
        // }
    },
    modules: {
    }
});