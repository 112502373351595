import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta';

Vue.use(VueRouter)
Vue.use(Meta)

// NOTE: Not sure why sometimes one of these works and sometimes only the other does
// () => import('@/views/NameHere.vue') <-- must have .vue
// require('@/views/NameHere') <-- No .vue

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/platforms',
    name: 'Platforms',
    component: () => import('@/views/Platforms.vue')
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import('@/views/Solutions.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },

  // PRODUCT PAGES

  {
    path: '/tides',
    name: 'Tides',
    components: require('@/views/Tides/Home')
  },
  {
    path: '/reva',
    name: 'REVA',
    components: require('@/views/reva/reva')
  },
  {
    path: '/content-management',
    name: 'Content Management',
    components: require('@/views/ContentManagement/ContentManagement')
  },

  // SOLUTIONS PAGES

  {
    path: '/case-studies',
    name: 'Case Studies',
    component: () => import('@/views/CaseStudies.vue')
  },
  {
    path: '/case-studies/:id',
    components: require('@/views/CaseStudy')
  },
  {
    path: '/government-solutions',
    name: 'Government Solutions',
    component: () => import('@/views/Government.vue')
  },

  // ABOUT US PAGES

  {
    path: '/careers',
    name: 'Careers',
    component: () => import('@/views/Careers.vue')
  },
  {
    path: '/careers/:id',
    components: require('@/views/Job.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/News.vue')
  },
  {
    path: '/event/:id',
    components: require('@/views/NewsArticle')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/terms-of-use',
    name: 'Terms',
    component: () => import('@/views/Terms.vue')
  },

  // {
  //   path: '/talent-cascade',
  //   name: 'Talent Cascade',
  //   components: () => import('@/views/TalentCascade/TalentCascade.vue')
  // },
  // {
  //   path: '/how-it-works',
  //   name: 'How it works',
  //   components: () => import('@/views/TalentCascade/HowItWorks.vue')
  // },

  // REDIRECT from old pages
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '/careers.html',
    redirect: 'careers'
  },

  {
    // redirect ALL old press releases
    path: '/press-release/*',
    redirect: 'news'
  },
  {
    path: '/about.html',
    redirect: 'about'
  },
  {
    path: '/business.html',
    redirect: 'about'
  },
  {
    path: '/contact.html',
    redirect: 'contact'
  },
  {
    path: '/sales.html',
    redirect: 'contact'
  },
  {
    path: '/terms-of-use.html',
    redirect: 'terms-of-use'
  },
  {
    path: '/government-solutions.html',
    redirect: 'solutions'
  },
  {
    path: '/defense-security.html',
    redirect: 'solutions'
  },
  {
    // TODO: Redirect this to 'govt' page when it's ready
    // ??? Maybe not this one? Depends if old content matches govt page
    path: '/decals.html',
    redirect: 'case-studies'
  },
  {
    path: '/principal-investigator-bios.html',
    redirect: 'government-solutions'
  },
  {
    path: '/contract-vehicles.html',
    redirect: 'government-solutions'
  },
  {
    path: '/instructional-systems-design.html',
    redirect: '/contract-vehicles.html'
  },
  {
    path: '/iitsec*',
    redirect: 'news'
  },
  {
    path: '/competency-skills-mgmt.html',
    redirect: '/'
  },
  {
    path: '/training-education.html',
    redirect: '/'
  },
  {
    path: '/talent-mgmt.html',
    redirect: '/'
  },
  {
    path: '/patent-search.html',
    redirect: '/'
  },
  {
    path: '/document-analysis.html',
    redirect: '/'
  },
  {
    path: '/intelligent-tutoring-systems.html',
    redirect: '/'
  },
  {
    path: '/business.html',
    redirect: '/'
  },
  {
    path: '/custom-solutions.html',
    redirect: 'contact'
  },
  {
    path: '/eduworks-engine.html',
    redirect: '/'
  },
  {
    path: '/cv-matching.html',
    redirect: '/'
  },
  {
    path: '/courseware-conversion.html',
    redirect: '/'
  },
  {
    path: '/assessment-generation.html',
    redirect: '/'
  },
  {
    path: '/current-projects.html',
    redirect: 'case-studies'
  },

  // 404 page, match any URL path not listed above
  {
    path: '*',
    name: 'Page Not Found',
    component: () => import('@/views/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router
