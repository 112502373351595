<template>
    <div role="main">
        <section class="section">
            <div class="container has-text-centered">
                <img style="width: max(50%, 600px); max-width:100%; border-radius: 1rem;"
                    src="./assets/waveform-banner.jpg"
                    alt="Can you hear the difference? Audio waveform fake, audio waveform real"
                    class="mb-5"
                />
                <h1 class="title xl is-1 mt-5">Introducing REVA</h1>
                <h2 class="title is-3">Voice security for the age of AI</h2>
            </div>
        </section>
        <section class="section">
            <div class="container content">
                <div class="columns is-centered">
                    <div class="column is-8">
                        <div class="columns">
                            <div class="column">
                                <p>
                                    The rise of artificial intelligence has
                                    provided adversaries with new tools to harm
                                    people, organizations, and communities.
                                    Audio deepfake technology is increasingly
                                    accessible, sophisticated, and difficult to
                                    detect. Attackers using this technology can
                                    impersonate trusted parties, execute false
                                    transactions, propagate false and harmful
                                    content, compromise public figures, and
                                    jeopardize relationships and reputations. A
                                    solution that detects suspicious or
                                    malicious patterns in voice communications
                                    must be a tenet of any organization’s
                                    cybersecurity strategy.
                                </p>
                                <p class="subtitle is-5" style="margin-left: 0">
                                    Audio manipulation presents a threat to
                                    organizational security
                                </p>
                                <p>
                                    REVA (Real-time Enhanced Voice
                                    Authentication) is a platform enabling
                                    enterprises, governments, and individuals to
                                    defend against the risk of financial loss,
                                    data compromise, and disinformation that
                                    audio deep fakes present.
                                </p>
                            </div>
                            <div class="column">
                                <img
                                    src="./assets/reva-animated-waveform.gif"
                                    style="border-radius: 1000px"
                                />
                                <img src="./assets/reva-logo-color.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-medium is-dark">
            <div class="columns is-centered">
                <div class="column is-8">
                    <div class="hero-body">
                        <h2 class="title">
                            Accurate results under real world conditions
                        </h2>
                        <div class="columns">
                            <div class="column">
                                <p>
                                    REVA provides an extended layer of security
                                    that shields you and your organization from
                                    the threat of voice manipulation and audio
                                    deep fakes. Working in real-time or
                                    asynchronously, REVA deploys proprietary
                                    algorithms that analyze an audio stream and
                                    alert you when tampering is detected. REVA
                                    provides your organization with real-time
                                    alerts of attacks, enabling you to react,
                                    respond and protect your people.
                                </p>
                            </div>
                            <div class="column is-narrow">
                                <router-link
                                    to="/contact"
                                    class="
                                        button
                                        is-primary is-radiusless
                                        has-text-weight-bold
                                        is-block
                                        mt-4
                                        is-inline-block
                                        primary-button
                                    "
                                >
                                    Request a demo
                                    <i
                                        class="
                                            fas
                                            fa-arrow-right
                                            ml-2
                                            primary-button-icon
                                        "
                                    ></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-medium is-light">
            <div class="hero-body container">
                <div class="has-text-centered">
                    <p class="is-uppercase">Use Cases</p>
                    <h3 class="title is-3 mb-5">
                        Protecting our partners across sectors
                    </h3>
                </div>

                <div class="columns is-centered">
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <i class="fas fa-university fa-5x"></i>
                                    </div>
                                    <div class="column">
                                        <p class="header has-text-weight-bold">
                                            Finance
                                        </p>
                                        <p>
                                            Protect high-value transactions and
                                            client relationships
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <i class="fas fa-phone-volume fa-5x"></i>
                                    </div>
                                    <div class="column">
                                        <p class="header has-text-weight-bold">
                                            Telecommunications
                                        </p>
                                        <p>
                                            Integrate into existing contact
                                            center systems
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="card">
                            <div class="card-content">
                                <div class="columns is-mobile">
                                    <div class="column is-narrow">
                                        <i class="fas fa-user-secret fa-5x"></i>
                                    </div>
                                    <div class="column">
                                        <p class="header has-text-weight-bold">
                                            Intelligence
                                        </p>
                                        <p>
                                            Defend against foreign and domestic
                                            adversaries
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-medium">
            <div class="hero-body has-text-centered">
                <p class="has-text-weight-bold is-uppercase">
                    See REVA in action
                </p>
                <h3 class="title is-3">Request a demo</h3>
                <router-link
                    to="/contact"
                    class="
                        button
                        is-large is-primary is-radiusless
                        has-text-weight-bold
                        is-block
                        mt-4
                        is-inline-block
                        primary-button
                    "
                >
                    Get Started
                    <i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                </router-link>
            </div>
           <div class="has-text-centered has-text-weight-bold is-size-6"> Distribution Statement "A" (Approved for Public Release, Distribution Unlimited)</div>
        </section>
    </div>
</template>

<script>
export default {
    name: "REVA",
    data: () => {
        return {
            name: "REVA",
        };
    },
    metaInfo: {
        title: "REVA",
        template: null,
    },
};
</script>

<style scoped>
.card {
    height: 100%;
}
</style>
